import React from 'react';

import Layout from '../components/furniture/Layout/Layout';

import generateTitle from '../utils/generateTitle';
import ContactForm from '../components/furniture/ContactForm/ContactForm';


const ContactPage = ({ location }) => {

  const pageTitle = generateTitle('Contact');

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <h1>Contact Us</h1>
      <p>Have something to share? Contact us!</p>

      <ContactForm />
    </Layout>
  );
};

export default ContactPage;
