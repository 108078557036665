import React, { createRef, useState } from 'react';
import Select from 'react-select';
import { navigate } from 'gatsby';
import Recaptcha from 'react-google-recaptcha';

import styles from './ContactForm.module.scss';

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY;
if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `);
}

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

const ContactForm = () => {
  const [state, setState] = useState({});
  const recaptchaRef = createRef();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-recaptcha="true"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      <p>
        <label>
          Your name:
          <br />
          <input className={styles.input} type="text" name="name" onChange={handleChange} />
        </label>
      </p>
      <p>
        <label>
          Your email:
          <br />
          <input className={styles.input} type="email" name="email" onChange={handleChange} />
        </label>
      </p>
      {/*<p>
        <label>
          What are you contacting us about today?
          <br />
          <Select
            className={styles.select}
            classNamePrefix="select"
            name="topic"
            options={[{
              label: 'I want to say hi',
              value: 'Saying hello',
            }, {
              label: 'I want to suggest a new feature',
              value: 'Feature request',
            }, {
                label: 'I want to suggest a new collection',
                value: 'New collection request',
            }, {
              label: 'I have spotted a mistake with a collection',
              value: 'Mistake',
            }, {
              label: 'I want to provide data for a new/existing collection',
              value: 'Data submission',
            }, {
              label: 'Other',
              value: 'Other',
            }]}
          />
        </label>
      </p>*/}
      <p>
        <label>
          Your message:
          <br />
          <textarea className={styles.message} name="message" onChange={handleChange} />
        </label>
      </p>
      <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
      <p>
        <button className={styles.button} type="submit">Submit</button>
      </p>
    </form>
  );
};

ContactForm.displayName = 'Form';

export default ContactForm;
